<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left> </template>

      <template #right>
        <Button
          icon="pi pi-download"
          class="p-button-help"
          @click="exportCSV($event)"
          v-tooltip="'طباعه'"
        />
      </template>
    </Toolbar>

    <DataTable
      ref="dt"
      :value="list"
      :paginator="true"
      class="p-datatable-customers table table-striped"
      :rows="10"
      dataKey="id"
      :rowHover="true"
      :selection.sync="selectedItems"
      :filters="filters"
      :expandedRows.sync="expandedRows"
      :loading="loading"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50, 100,500,1000,10000]"
      currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
    >
      <template #header>
        <div class="table-header">
          السجلات
        </div>
      </template>
      <template #empty>
        لا يوجد بيانات
      </template>
      <template #loading>
        يتم تحميل البيانات. يرجي الانتظار...
      </template>

      <Column field="id" header="الكود">
        <template #body="slotProps">
          {{ slotProps.index + 1 }}
        </template>
      </Column>
      <Column
        field="user.id"
        header="معرف الموظف"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            type="number"
            v-model="filters['user.id']"
            class="p-column-filter"
            placeholder="بحث بمعرف الموظف"
          />
        </template>
      </Column>
      <Column
        field="user.userName"
        header="اسم الموظف"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            v-model="filters['user.userName']"
            class="p-column-filter"
            placeholder="بحث بأسم الموظف"
          />
        </template>
      </Column>
      <Column field="type" header="الحدث" :sortable="true">
        <template #filter>
          <InputText
            v-model="filters['type']"
            class="p-column-filter"
            placeholder="بحث بالحدث"
          />
        </template>
      </Column>
      <Column
        field="page"
        header="الشاشه"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            v-model="filters['page']"
            class="p-column-filter"
            placeholder="بحث بالشاشه"
          />
        </template>
      </Column>
      <Column
        field="dataId"
        header="معرف المحتوي"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            type="number"
            v-model="filters['dataId']"
            class="p-column-filter"
            placeholder="بحث بمعرف المحتوي"
          />
        </template>
      </Column>
      <Column
        field="createdAt"
        header="تاريخ"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #body="slotProps">
          {{ $durationFormatFull(slotProps.data.createdAt) }}
        </template>

        <template #filter>
          <InputText
            type="date"
            v-model="filters['createdAt']"
            class="p-column-filter"
            placeholder="بحث بالتاريخ"
          />
        </template>
      </Column>

      <Column :expander="true" headerStyle="width: 3rem" />

      <template #expansion="slotProps">
        <div class="orders-subtable">
          <DataTable
            :value="backBody(slotProps.data.body)"
            class="p-datatable-customers table table-striped"
          >
            <template #empty>
              لا يوجد بيانات
            </template>

            <Column field="key" header="الفيلد" />
            <Column field="value" header="القيمه" />
          </DataTable>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      selectedItems: [],
      expandedRows: [],
      loading: true,
      filters: {},
    };
  },
  methods: {
    getData() {
      this.$http.get(`company/getLogs`).then(
        (response) => {
          this.loading = false;
          this.list = response.data;
        },
        (err) => {
          this.loading = false;
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    },
    backBody(obj) {
      const list = [];
      for (const key in obj) {
        list.push({
          key,
          value: obj[key],
        });
      }
      return list;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
  },
  created() {
    if (!this.$checkRoles('logsVeiw')) {
      this.$router.push('/admin/');
    }
    this.getData();
  },
};
</script>
